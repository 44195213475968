interface ResolvedAddress {
  address_provider: string;
  address: string;
  partial_match?: boolean;

  business_name?: string;
  subpremise?: string;
  street_number?: string;
  street?: string;
  locality?: string;
  sublocality?: string;
  postal_code?: string;

  place_id: string;
  address_type?: string;

  latitude: number;
  longitude: number;
}

class GoogleAutocompleteResolver {

  static defaultAutoComplete(addressField: HTMLInputElement) : google.maps.places.Autocomplete {
    return new google.maps.places.Autocomplete(addressField, {
      fields: ['address_components', 'name', 'formatted_address', 'geometry', 'place_id', 'types'],
      bounds: GoogleAutocompleteResolver.defaultBounds(),
      strictBounds: true,
    });
  }

  static defaultBounds(): google.maps.LatLngBounds {
    return new google.maps.LatLngBounds(
      new google.maps.LatLng(-52.722466, 165.743764),
      new google.maps.LatLng(-28.877322, -175.123508),
    );
  }

  resolveFromAutocomplete(inputAddress: string, place?: google.maps.places.PlaceResult): ResolvedAddress | null {
    if (!place?.address_components || !place?.types) return null;

    // Customer chose from the autocomplete menu; inputAddress is already the full autocompleted address.
    const result: ResolvedAddress = {
      address_provider: "google",

      // The place result's formatted_address usually matches the autocompleted text exactly (except
      // for the postcode/country), but it doesn't in the case where they entered a street number
      // suffix at a property that isn't actually a separate title (eg. 47A when there's only
      // officially 47) - the place result has just the title's street number (eg. 47), whereas the
      // autocompleted input address retains the entered number (eg. 47A). So keeping the input
      // address is better than taking it from the given place data in that case. That said, we d
      // want to normalize a little here, such as replacing the short form of street names with long.
      address: this.normalizeStreetName(inputAddress, place),

      subpremise: this.findAddressComponent("subpremise", place.address_components),
      street_number: this.findAddressComponent("street_number", place.address_components),
      street: this.findAddressComponent("route", place.address_components),
      sublocality: this.findAddressComponent("sublocality_level_1", place.address_components),
      locality: this.findAddressComponent("locality", place.address_components) ??
                this.findAddressComponent("postal_town", place.address_components) ??
                this.findAddressComponent("administrative_area_level_1", place.address_components),
      postal_code: this.findAddressComponent("postal_code", place.address_components),

      // We use this in the AddressResolver. Again, always present in reality.
      address_type: place.types?.join(','),

      // Again, these fields are actually always present since we specified geometry in the types
      // option to Autocomplete, but make typescript happy.
      place_id: place.place_id ?? "",
      latitude: place.geometry?.location?.lat() || 0,
      longitude: place.geometry?.location?.lng() || 0,
    };

    // If the place has a name *other than* its street address, this generally means it's a named
    // place such as a business, or POI (most of which we will reject in the server-side resolver).
    // So put the place name in the business name field and keep the formatted_address rather than
    // the autocomplete input, which is often just a partial address (eg. for the DE office we get
    // "Delivereasy Office Taranaki Street" not "Delivereasy Office 79 Taranaki St").
    if (place.name && place.formatted_address && !place.formatted_address.startsWith(place.name)) {
      result.business_name = place.name;
      result.address = place.formatted_address;
    }

    return result;
  }

  private findAddressComponent(type: string, components?: google.maps.GeocoderAddressComponent[]) {
    return components?.find(c => c.types.includes(type) && c.long_name != '')?.long_name;
  }

  private normalizeStreetName(inputAddress: string, place?: google.maps.places.PlaceResult) {
    const streetComponent = place?.address_components?.find(c => c.types.includes('route') && c.long_name != '' && c.short_name != '');

    if (streetComponent && inputAddress.includes(streetComponent.short_name) && !inputAddress.includes(streetComponent.long_name)) {
      return inputAddress.replace(streetComponent.short_name, streetComponent.long_name);
    } else {
      return inputAddress;
    }
  }
}

export { GoogleAutocompleteResolver, ResolvedAddress };
